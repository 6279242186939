import React, { useEffect } from "react";
import { BASE_URL } from "./../../global";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

import {
  Button,
  TableContainer,
  TextField,
  Autocomplete,
  Box,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";

import { companyList } from "../../utils/components/config";

const TextDisplay = ({ text }) => (
  <div style={{ whiteSpace: "pre-wrap" }}>{text?.replace(/\\n/g, "\n")}</div>
);

const defaultHeaderMapping = {
  equipmentId13:
    "Name of Pressure Plant / Vessel /  Gas Holder (Please select name from list. If not found in the list, keep it blank and mention in next column.)",
  distinctiveNumber:
    "Description and distinctive number of Pressure Plant /Vessel/ Gas Holder",
  nameOfMaker: "Name of Maker",
  natureOfProcess:
    "Nature of process in which pressure plant  / vessel is used",
  dateOfConstruction13: "Particulars of Plant/ Vessel/ Gas Holder 5(a)",
  thicknessOfWall: "Particulars of Plant/ Vessel/ Gas Holder 5(b)",
  firstDateOfUse: "Particulars of Plant/ Vessel/ Gas Holder 5(c)",
  safeWorkingPressure: "Particulars of Plant/ Vessel/ Gas Holder 5(d)",
  lastExternalExamination: "Date of last examinations 6(i)",
  lastInternalExamination: "Date of last examinations 6(ii)",
  lastHydraulicExamination: "Date of last examinations 6(iii)",
  lastUltrasonicExamination: "Date of last examinations 6(iv)",
  examinationPurpose: "Whether Lagging was removed for purpose of examination",
  descriptionOfExaminations:
    "Description of examinations carried out and findings 8(i)",
  externalExamination:
    "Description of examinations carried out and findings 8(ii)",
  internalExamination:
    "Description of examinations carried out and findings 8(iii)",
  hydraulicTestExamination:
    "Description of examinations carried out and findings 8(iv)",
  safeWorkingPressure9:
    "Safe Working Pressure calculated as per methods given in sub rule 5 (c ) (ii) for sizing cylinders",
  vessel: "Condition of Pressure Plant 10(A)",
  piping: "Condition of Pressure Plant 10(B)",
  pressureGauges: "ConditionOfFittingsPressureGauges(i)",
  safetyValve: "ConditionOfFittingsPressureGauges(ii)",
  stopValve: "ConditionOfFittingsPressureGauges(iii)",
  reducingValve: "ConditionOfFittingsPressureGauges(iv)",
  additinalSafety: "ConditionOfFittingsPressureGauges(v)",
  otherDevices: "ConditionOfFittingsPressureGauges(vi)",
  appliancesMaintaince: "ConditionOfFittingsPressureGauges(vii)",
  recommendedAfterExamination:
    "Safe working pressure recommended after examination (specify allowances made for condition of working such as heat, corrosion etc.)",
  repairsIfAnyAndPeriod:
    "Specify repairs if any and period within which they should be executed",
  specifyReducedWorkingPressure:
    "Specify reduced working pressure pending repairs",
  conditionSubject:
    "Other observation / conditions subject to which the plant is to be operated",
  testingNo: "Testing No",
  companyName: "Company Name",
  reportNumber: "Report Number",
  reportDate: "Report Date",
  equipmentId:
    "Type of hoist, lift, lifting machine, chains, ropes and lifting tackles.",
  typeIdentificationNumber:
    "Identification numbers and description of hoist, lift, lifting machine, chains, ropes and lifting tackles.",
  capacity: "Capacity",
  location: "Location",
  dateOfConstruction:
    "Date of construction or reconstruction and the date when the hoist, lift, lifting machine, chains, ropes and lifting tackles were first taken into use in the factory.",
  dateOfExamination:
    "4. Date of last examinations made under section 28 (1) (a) (ii) and 29 (1) (a) (iii) and by whom it was carried out.",
  mainatance:
    "5.A. Maintenance : (List of parts, if any, which were inaccessible) Are the following parts of the hoist or lift properly maintained and in good working order ? If not, state what defects have been found :-",
  enclosureOfHoist: "a) Enclosure of hoistway or liftway.",
  landingGates: "b) Landing gates and cage gate (s).",
  interlocksOnLanding: "c) Interlocks on the landing gates and cage gate(s).",
  gateFastings: "d) Other gates fastenings.",
  platformFittings:
    "e) Cage and Platform and fittings, cage guides, buffers, interior of the hoistway or liftway.",
  overrunningDevices: "f) Overrunning devices.",
  suspensionRopes: "g) Suspension ropes or chains and their attachments.",
  safetyGear:
    "h) Safety gear, i.e. arrangements for preventing fall of platform or cage brakes.",
  breaks: "i) Brakes",
  spurGaring: "j) Work of spur gearing",
  electronicalEquipment: "k) Other electrical equipment.",
  otherParts: "l) Other Parts",
  numberOfCertification:
    "B. Date and number of the certificate relating to any test and examination made under sub-rule (1) of rule 64 together with the name of the person who issued the certificate.",
  dateOfAnnealing:
    "C. (i) Date of annealing or the heat treatment of the chain and lifting tackle carried out under sub – rule (5) of rule 64 and by whom it was carried out.",
  defectDescription:
    "(ii) Particulars of any defect found at any such examination or after annealing and affecting the safe working load and of the steps taken to remedy such defect.",
  repairsrenewals:
    "(iii) Repairs, renewals or alterations (if any) required and the period within which they should be executed and maximum safe working load subject to repairs, renewals or alterations (if any).",
  testingFrequency: "Testing Frequency",
  certificateValidity: "Certificate Validity Date",
  inspectionDate: "Date of Testing",
  occupier: "NAME OF OCCUPIER",
  client_name: "NAME OF FACTORY",
  client_address: "ADDRESS (FACTORY)",
  client_taluka: "TALUKA/SUB-LOCATION (FACTORY)",
  client_district: "DISTRICT (FACTORY)",
  client_pinCode: "PINCODE (FACTORY)",
  client_email: "EMAIL (OCCUPIER)",
  client_contactDetails: "PHONE NUMBER (OCCUPIER)",
  competent_personName: "NAME (CP)",
  competent_address: "ADDRESS (CP)",
  competent_taluka: "TALUKA / SUB-LOCATION (CP)",
  competent_district: "DISTRICT (CP)",
  competent_pinCode: "PINCODE (CP)",
  competent_qualification: "DISTRICT (CP)",
  competent_contactNumber: "PHONE (CP)",
  competent_email: "E-MAIL (CP)",
};

var form11Columns = [
  {
    id: "id",
    label: "Sr.No.",
    align: "center",
    minWidth: 2,
  },
  {
    id: "typeIdentificationNumber",
    label: "Name of Equipment / Plant",
    align: "left",
    minWidth: 30,
  },
  {
    id: "location",
    label: "Location",
    align: "center",
    minWidth: 15,
  },
  {
    id: "capacity",
    label: "Safe Working Load",
    align: "center",
    minWidth: 10,
  },
  {
    id: "dateOfExamination",
    label: "Last Examined by Whom & Date",
    align: "center",
    minWidth: 20,
  },
  {
    id: "inspectionDate",
    label: "Date of Testing",
    align: "center",
    minWidth: 10,
  },
  {
    id: "certificateValidity",
    label: "Next due Date of Testing",
    align: "center",
    minWidth: 10,
  },
];

var form13Columns = [
  {
    id: "id",
    label: "Sr.No.",
    align: "center",
    minWidth: 2,
  },
  // {
  //   id: "equipment_name",
  //   label: "Name of Equipment",
  //   align: "left",
  //   minWidth: 40,
  // },
  {
    id: "distinctiveNumber",
    label: "Name of Equipment / Plant",
    align: "left",
    minWidth: 24,
  },
  {
    id: "safeWorkingPressure",
    label: "Safe Working Load",
    align: "center",
    minWidth: 10,
  },
  {
    id: "utrasonicTestDate",
    label: "Ultrasonic Examination Date of Testing",
    align: "center",
    minWidth: 10,
  },
  {
    id: "utrasonicNextDueDateCalculate",
    label: "Ultrasonic Next Due Date of Testing",
    align: "center",
    minWidth: 10,
  },,
  // {
  //   id: "internalTestDate",
  //   label: "Internal Examination Date of Testing",
  //   align: "left",
  //   minWidth: 40,
  // },
  // {
  //   id: "internalExaminationDueDate",
  //   label: "Internal Next Due Date of Testing",
  //   align: "left",
  //   minWidth: 40,
  // },
  {
    id: "hydraulicTestDate",
    label: "Hydraulic Examination Date of Testing",
    align: "center",
    minWidth: 10,
  },
  {
    id: "hydraulicNextDueDateCalculate",
    label: "Hydraulic Next Due Date of Testing",
    align: "center",
    minWidth: 10,
  },  
  {
    id: "ultrasonicTest",
    label: "Observed Thickness",
    align: "left",
    minWidth: 24,
  }
];

const notNeededColumn = [
  "id",
  "templateId",
  "templateValues",
  "reportNo",
  "qrCodeNo",
  "name",
  "companyName",
  "testingFrequency",
  "location",
  "capacity",
  "factoryId",
  "addressFactory",
  "shopId",
  "certificateValidity",
  "Occupier",
  "conditionsOfFittings",
  "whomCarriedOut",
  "dateOf",
  "gasHolder",
  "internalExaminationDueDate",
  "internalTestDate",
  "competentPerson",
  "hydraulicTestDate",
  "utrasonicTestDate",
  "ultrasonicTest",
  "reportNumber",
  "reportDate",
];

const DishReport = (props) => {
  const printComponentRef = React.useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalRows, setTotalRows] = React.useState(0);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [finalData, setFinalData] = React.useState([]);
  const [templateList, setTemplateList] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState({
    templateId: null,
    factoryId: null,
    shopId: null,
    companyID: null,
  });

  const [factoryList, setFactoryList] = React.useState([]);

  // console.log(selectedIds);

  let columns = selectedIds?.templateId == 12 ? form13Columns : form11Columns;
  const getQuery = (count) => {
    let whereConditions = "";
    // let pagination_settings = "";

    if (selectedIds?.templateId) {
      whereConditions += ` WHERE reportValues.templateId = ${selectedIds?.templateId} `;
    }

    if (selectedIds?.factoryId) {
      let colName =
        "REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.factoryId'), '\"', '')";
      whereConditions += ` AND ${colName} = ${selectedIds?.factoryId} `;
    }

    if (selectedIds?.companyID) {
      let colName =
        "REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.companyName'), '\"', '')";
      whereConditions += ` AND ${colName} = ${selectedIds?.companyID} `;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " WHERE ";
      } else {
        whereConditions += " AND ";
      }

      // Use JSON_EXTRACT to get inspectionDate from JSON
      whereConditions += ` REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.inspectionDate'), '\"', '') BETWEEN '${moment(
        from
      ).format("YYYY-MM-DD")}' AND '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (selectedIds?.shopId) {
      let colName =
        "REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.shopId'), '\"', '')";
      whereConditions += ` AND ${colName} = ${selectedIds?.shopId} `;
    }

    // if (rowsPerPage != -1) {
    //   pagination_settings = `limit ${rowsPerPage} offset ${
    //     rowsPerPage * Number(page)
    //   }`;
    // }

    let data;

    if (selectedIds?.templateId == 12) {
      data = {
        query: `SELECT reportValues.templateId,reportValues.id, reportValues.id AS testingNo,reportValues.*,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.equipmentId'), '\"', '') AS equipmentId13,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.ultrasonicTest'), '\"', '') AS ultrasonicTest,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.companyName'), '\"', '') AS companyName,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportNumber'), '\"', '') AS reportNumber,
        REPLACE(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$'), '\n', ' '), '\"', '') AS templateValues,
        DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportDate'), '\"', ''), '%d-%m-%Y') AS reportDate,
        DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.certificateValidity'), '\"', ''), '%d-%m-%Y') AS certificateValidity,        
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.internalTestDate'), '\"', '') AS internalTestDate,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.hydraulicTestDate'), '\"', '') AS hydraulicTestDate,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.utrasonicTestDate'), '\"', '') AS utrasonicTestDate,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.internalExaminationDueDate'), '\"', '') AS internalExaminationDueDate,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.competentPerson'), '\"', '') AS competentPerson,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.location'), '\"', '') AS location,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.capacity'), '\"', '') AS capacity,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.factoryId'), '\"', '') AS factoryId,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.addressFactory'), '\"', '') AS addressFactory,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.shopId'), '\"', '') AS shopId,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.distinctiveNumber'), '\"', '') AS distinctiveNumber,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.nameOfMaker'), '\"', '') AS nameOfMaker,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.natureOfProcess'), '\"', '') AS natureOfProcess,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfConstruction'), '\"', '') AS dateOfConstruction13,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.thicknessOfWall'), '\"', '') AS thicknessOfWall,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.firstDateOfUse'), '\"', '') AS firstDateOfUse,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.safeWorkingPressure'), '\"', '') AS safeWorkingPressure,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.gasHolder'), '\"', '') AS gasHolder,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOf'), '\"', '') AS dateOf,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.lastExternalExamination'), '\"', '') AS lastExternalExamination,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.lastInternalExamination'), '\"', '') AS lastInternalExamination,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.lastHydraulicExamination'), '\"', '') AS lastHydraulicExamination,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.lastUltrasonicExamination'), '\"', '') AS lastUltrasonicExamination,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.whomCarriedOut'), '\"', '') AS whomCarriedOut,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.examinationPurpose'), '\"', '') AS examinationPurpose,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.descriptionOfExaminations'), '\"', '') AS descriptionOfExaminations,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.externalExamination'), '\"', '') AS externalExamination,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.internalExamination'), '\"', '') AS internalExamination,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.hydraulicTestExamination'), '\"', '') AS hydraulicTestExamination,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.safeWorkingPressure9'), '\"', '') AS safeWorkingPressure9,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.vessel'), '\"', '') AS vessel,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.piping'), '\"', '') AS piping,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.conditionsOfFittings'), '\"', '') AS conditionsOfFittings,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.pressureGauges'), '\"', '') AS pressureGauges,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.safetyValve'), '\"', '') AS safetyValve,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.stopValve'), '\"', '') AS stopValve,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reducingValve'), '\"', '') AS reducingValve,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.additinalSafety'), '\"', '') AS additinalSafety,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.otherDevices'), '\"', '') AS otherDevices,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.appliancesMaintaince'), '\"', '') AS appliancesMaintaince,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.recommendedAfterExamination'), '\"', '') AS recommendedAfterExamination,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.repairsIfAnyAndPeriod'), '\"', '') AS repairsIfAnyAndPeriod,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.specifyReducedWorkingPressure'), '\"', '') AS specifyReducedWorkingPressure,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.testingFrequency'), '\"', '') AS testingFrequency,
              templates.name,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.conditionSubject'), '\"', '') AS conditionSubject,
              DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.inspectionDate'), '\"', ''), '%d-%m-%Y') AS inspectionDate,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.Occupier'), '\"', '') AS occupier,
              clients.name AS client_name,
              addresses.address AS client_address,
              clients.taluka AS client_taluka,
              clients.district AS client_district,
              clients.pinCode AS client_pinCode,
              clients.emailId AS client_email,
              clients.contactDetails AS client_contactDetails,
              competents.personName AS competent_personName,
              competents.address AS competent_address,
              competents.taluka AS competent_taluka,
              competents.qualification AS competent_qualification,
              competents.pinCode AS competent_pinCode,
              competents.contactNumber AS competent_contactNumber,
              competents.email AS competent_email,
              competents.district AS competent_district
              FROM reportValues
              LEFT JOIN templates ON reportValues.templateId = templates.id
              LEFT JOIN clients ON JSON_EXTRACT(reportValues.templateValues, '$.factoryId') = clients.id
              LEFT JOIN addresses ON addresses.id = SUBSTRING_INDEX(clients.address, ',', 1)
              LEFT JOIN competents ON JSON_EXTRACT(reportValues.templateValues, '$.competentPerson') = competents.id
            ${whereConditions}
            ORDER BY id DESC`,
      };
    } else {
      data = {
        query: `SELECT reportValues.templateId,reportValues.id,
        reportValues.id AS testingNo, reportValues.*,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.equipmentId'), '\"', '') AS equipmentId,
        REPLACE(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.typeIdentificationNumber'), '\"', ''), '\\n', ' ') AS typeIdentificationNumber,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfConstruction'), '\"', '') AS dateOfConstruction,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfExamination'), '\"', '') AS dateOfExamination,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.mainatance'), '\"', '') AS mainatance,
        REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.enclosureOfHoist'), '\"', '') AS enclosureOfHoist,
  REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.landingGates'), '\"', '') AS landingGates,
   REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.interlocksOnLanding'), '\"', '') AS interlocksOnLanding,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.gateFastings'), '\"', '') AS gateFastings,
               REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.platformFittings'), '\"', '') AS platformFittings,
               REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.overrunningDevices'), '\"', '') AS overrunningDevices,
               REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.suspensionRopes'), '\"', '') AS suspensionRopes,
               REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.safetyGear'), '\"', '') AS safetyGear,
                REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.breaks'), '\"', '') AS breaks,
               REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.spurGaring'), '\"', '') AS spurGaring,
             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.electronicalEquipment'), '\"', '') AS electronicalEquipment,
             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.otherParts'), '\"', '') AS otherParts,
             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.numberOfCertification'), '\"', '') AS numberOfCertification,
             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.companyName'), '\"', '') AS companyName,
             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportNumber'), '\"', '') AS reportNumber,
   REPLACE(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$'), '\n', ' '), '\"', '') AS templateValues,
          DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportDate'), '\"', ''), '%d-%m-%Y') AS reportDate,
          DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.certificateValidity'), '\"', ''), '%d-%m-%Y') AS certificateValidity,        
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.location'), '\"', '') AS location,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.capacity'), '\"', '') AS capacity,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.factoryId'), '\"', '') AS factoryId,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.addressFactory'), '\"', '') AS addressFactory,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.shopId'), '\"', '') AS shopId,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.testingFrequency'), '\"', '') AS testingFrequency,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfAnnealing'), '\"', '') AS dateOfAnnealing,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.defectDescription'), '\"', '') AS defectDescription,
              templates.name,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.repairsrenewals'), '\"', '') AS repairsrenewals,
              DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.inspectionDate'), '\"', ''), '%d-%m-%Y') AS inspectionDate,
              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.Occupier'), '\"', '') AS occupier,
              clients.name AS client_name,
              addresses.address AS client_address,
              clients.taluka AS client_taluka,
              clients.district AS client_district,
              clients.pinCode AS client_pinCode,
              clients.emailId AS client_email,
              clients.contactDetails AS client_contactDetails,
              competents.personName AS competent_personName,
              competents.address AS competent_address,
              competents.taluka AS competent_taluka,
              competents.qualification AS competent_qualification,
              competents.pinCode AS competent_pinCode,
              competents.contactNumber AS competent_contactNumber,
              competents.email AS competent_email,
              competents.district AS competent_district
              FROM reportValues
              LEFT JOIN templates ON reportValues.templateId = templates.id
              LEFT JOIN clients ON JSON_EXTRACT(reportValues.templateValues, '$.factoryId') = clients.id
              LEFT JOIN addresses ON addresses.id = SUBSTRING_INDEX(clients.address, ',', 1)
              LEFT JOIN competents ON JSON_EXTRACT(reportValues.templateValues, '$.competentPerson') = competents.id
            ${whereConditions}
            ORDER BY id DESC`,
      };
    }

    // all data
    //     data = {
    //       query: `SELECT reportValues.templateId,reportValues.id, reportValues.*,
    //       REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.equipmentId'), '\"', '') AS equipmentId,
    //       REPLACE(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.typeIdentificationNumber'), '\"', ''), '\\n', ' ') AS typeIdentificationNumber,
    //       REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfConstruction'), '\"', '') AS dateOfConstruction,
    //       REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfExamination'), '\"', '') AS dateOfExamination,
    //       REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.mainatance'), '\"', '') AS mainatance,
    //       REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.enclosureOfHoist'), '\"', '') AS enclosureOfHoist,
    // REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.landingGates'), '\"', '') AS landingGates,
    //  REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.interlocksOnLanding'), '\"', '') AS interlocksOnLanding,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.gateFastings'), '\"', '') AS gateFastings,
    //              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.platformFittings'), '\"', '') AS platformFittings,
    //              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.overrunningDevices'), '\"', '') AS overrunningDevices,
    //              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.suspensionRopes'), '\"', '') AS suspensionRopes,
    //              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.safetyGear'), '\"', '') AS safetyGear,
    //               REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.breaks'), '\"', '') AS breaks,
    //              REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.spurGaring'), '\"', '') AS spurGaring,
    //            REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.electronicalEquipment'), '\"', '') AS electronicalEquipment,
    //            REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.otherParts'), '\"', '') AS otherParts,
    //            REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.numberOfCertification'), '\"', '') AS numberOfCertification,
    //            REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.ultrasonicTest'), '\"', '') AS ultrasonicTest,
    //            REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.companyName'), '\"', '') AS companyName,
    //            REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportNumber'), '\"', '') AS reportNumber,
    //  REPLACE(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$'), '\n', ' '), '\"', '') AS templateValues,
    //         DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportDate'), '\"', ''), '%d-%m-%Y') AS reportDate,
    //         DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.certificateValidity'), '\"', ''), '%d-%m-%Y') AS certificateValidity,
    //         DATE_ADD(STR_TO_DATE(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.utrasonicTestDate'), '\"', ''), '%Y-%m-%d'), INTERVAL 6 MONTH) AS utrasonicNextDueDateCalculate,
    //         DATE_ADD(STR_TO_DATE(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.hydraulicTestDate'), '\"', ''), '%Y-%m-%d'), INTERVAL 2 YEAR) AS hydraulicNextDueDateCalculate,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.internalTestDate'), '\"', '') AS internalTestDate,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.hydraulicTestDate'), '\"', '') AS hydraulicTestDate,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.utrasonicTestDate'), '\"', '') AS utrasonicTestDate,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.safeWorkingPressure'), '\"', '') AS safeWorkingPressure,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.internalExaminationDueDate'), '\"', '') AS internalExaminationDueDate,
    //
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.competentPerson'), '\"', '') AS competentPerson,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.location'), '\"', '') AS location,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.capacity'), '\"', '') AS capacity,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.factoryId'), '\"', '') AS factoryId,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.addressFactory'), '\"', '') AS addressFactory,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.shopId'), '\"', '') AS shopId,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.location13'), '\"', '') AS location13,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.nameOfMaker'), '\"', '') AS nameOfMaker,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.natureOfProcess'), '\"', '') AS natureOfProcess,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.particularsOfPlant'), '\"', '') AS particularsOfPlant,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.thicknessOfWall'), '\"', '') AS thicknessOfWall,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.firstDateOfUse'), '\"', '') AS firstDateOfUse,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.gasHolder'), '\"', '') AS gasHolder,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOf'), '\"', '') AS dateOf,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.lastExternalExamination'), '\"', '') AS lastExternalExamination,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.lastInternalExamination'), '\"', '') AS lastInternalExamination,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.lastHydraulicExamination'), '\"', '') AS lastHydraulicExamination,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.lastUltrasonicExamination'), '\"', '') AS lastUltrasonicExamination,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.whomCarriedOut'), '\"', '') AS whomCarriedOut,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.examinationPurpose'), '\"', '') AS examinationPurpose,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.descriptionOfExaminations'), '\"', '') AS descriptionOfExaminations,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.externalExamination'), '\"', '') AS externalExamination,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.internalExamination'), '\"', '') AS internalExamination,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.hydraulicTestExamination'), '\"', '') AS hydraulicTestExamination,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.safeWorkingPressure9'), '\"', '') AS safeWorkingPressure9,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.conditionOfPressure'), '\"', '') AS conditionOfPressure,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.vessel'), '\"', '') AS vessel,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.piping'), '\"', '') AS piping,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.conditionsOfFittings'), '\"', '') AS conditionsOfFittings,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.pressureGauges'), '\"', '') AS pressureGauges,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.safetyValve'), '\"', '') AS safetyValve,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.stopValve'), '\"', '') AS stopValve,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reducingValve'), '\"', '') AS reducingValve,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.additinalSafety'), '\"', '') AS additinalSafety,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.otherDevices'), '\"', '') AS otherDevices,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.appliancesMaintaince'), '\"', '') AS appliancesMaintaince,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.recommendedAfterExamination'), '\"', '') AS recommendedAfterExamination,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.repairsIfAnyAndPeriod'), '\"', '') AS repairsIfAnyAndPeriod,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.specifyReducedWorkingPressure'), '\"', '') AS specifyReducedWorkingPressure,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.conditionSubject'), '\"', '') AS conditionSubject,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.testingFrequency'), '\"', '') AS testingFrequency,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfAnnealing'), '\"', '') AS dateOfAnnealing,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.defectDescription'), '\"', '') AS defectDescription,
    //             templates.name,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.repairsrenewals'), '\"', '') AS repairsrenewals,
    //             DATE_FORMAT(REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.inspectionDate'), '\"', ''), '%d-%m-%Y') AS inspectionDate,
    //             REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.Occupier'), '\"', '') AS occupier,
    //             clients.name AS client_name,
    //             addresses.address AS client_address,
    //             clients.taluka AS client_taluka,
    //             clients.district AS client_district,
    //             clients.pinCode AS client_pinCode,
    //             clients.emailId AS client_email,
    //             clients.contactDetails AS client_contactDetails,
    //             competents.personName AS competent_personName,
    //             competents.address AS competent_address,
    //             competents.taluka AS competent_taluka,
    //             competents.qualification AS competent_qualification,
    //             competents.pinCode AS competent_pinCode,
    //             competents.contactNumber AS competent_contactNumber,
    //             competents.email AS competent_email,
    //             competents.district AS competent_district
    //             FROM reportValues
    //             LEFT JOIN templates ON reportValues.templateId = templates.id
    //             LEFT JOIN clients ON JSON_EXTRACT(reportValues.templateValues, '$.factoryId') = clients.id
    //             LEFT JOIN addresses ON addresses.id = SUBSTRING_INDEX(clients.address, ',', 1)
    //             LEFT JOIN competents ON JSON_EXTRACT(reportValues.templateValues, '$.competentPerson') = competents.id
    //           ${whereConditions}
    //           ORDER BY id DESC
    //           ${pagination_settings}`,
    //     };

    // let data = {
    //   query: `
    //       SELECT
    //         reportValues.templateId,
    //         reportValues.id,
    //         reportValues.*,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.companyName'), '\"', '') AS companyName,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportNumber'), '\"', '') AS reportNumber,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportDate'), '\"', '') AS reportDate,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.factoryId'), '\"', '') AS factoryId,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.occupier'), '\"', '') AS occupier,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.addressFactory'), '\"', '') AS addressFactory,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.shopId'), '\"', '') AS shopId,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.equipmentId'), '\"', '') AS equipmentId,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.typeIdentificationNumber'), '\"', '') AS typeIdentificationNumber,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.capacity'), '\"', '') AS capacity,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.location'), '\"', '') AS location,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfConstruction'), '\"', '') AS dateOfConstruction,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.dateOfExamination'), '\"', '') AS dateOfExamination,

    //

    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.inspectionDate'), '\"', '') AS inspectionDate,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.certificateValidity'), '\"', '') AS certificateValidity,
    //         REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.competentPerson'), '\"', '') AS competentPerson,
    //         templates.name,
    //         clients.name AS client_name,
    //         addresses.address AS client_address,
    //         clients.emailId AS client_email
    //       FROM reportValues
    //       LEFT JOIN templates ON reportValues.templateId = templates.id
    //       LEFT JOIN clients ON JSON_EXTRACT(reportValues.templateValues, '$.factoryId') = clients.id
    //       LEFT JOIN addresses ON addresses.id = SUBSTRING_INDEX(clients.address, ',', 1)
    //       ${whereConditions}
    //       ORDER BY id DESC
    //       ${pagination_settings}`,
    // };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM reportValues LEFT JOIN templates ON reportValues.templateId = templates.id ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  const getAllFactory = () => {
    axiosWithToken
      .get(BASE_URL + `clients`)
      .then((res) => {
        setFactoryList(res.data);
      })
      .catch((err) => {
        console.log(" error: ", err);
      });
  };

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        // let updatedData = res.data.map((item) => ({
        //   ...item,
        //   distinctiveNumber: item.distinctiveNumber?.replaceAll("\n", " ")?.replaceAll("\\n", " "),
        //   typeIdentificationNumber: item.typeIdentificationNumber?.replaceAll("\n", " ")?.replaceAll("\\n", " "),
        // }));
        let updatedData = res.data.map((item) => {
          let updatedItem = {};
          for (let key in item) {
            if (item.hasOwnProperty(key)) {
              updatedItem[key] = (typeof item[key] === 'string' && item[key])
                ? item[key]?.replaceAll("\n", " ")?.replaceAll("\\n", " ") 
                : item[key];  
            }
          }
          return updatedItem;
        });

        setFinalData(updatedData); 
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }
  const [competentPerson, setcompetentPerson] = React.useState([]);
  function fetchCompnetentData() {
    axiosWithToken
      .get(BASE_URL + `competents`)
      .then((res) => {
        const ids = finalData
          .map((data) => data.competentPerson)
          .filter((value, index, self) => self.indexOf(value) === index);
        const filteredCompetentPersons = res.data.filter((cp) =>
          ids.includes(cp.id.toString())
        );
        setcompetentPerson(filteredCompetentPersons[0]);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
  };

  const getAllTemplate = () => {
    axiosWithToken
      .get(BASE_URL + `templates`)
      .then((res) => {
        setTemplateList(res.data);
      })
      .catch((err) => {
        console.log(" error: ", err);
      });
  };

  useEffect(() => {
    getTotalRows();
    getAllTemplate();
    getAllFactory();
  }, []);

  useEffect(() => {
    fetchCompnetentData();
  }, [finalData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  return (
    <div>
      <div style={{ margin: "5px", padding: "5px" }}>
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
          }}
        >
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            onChange={(event, newValue) => {
              setSelectedIds((prevState) => ({
                ...prevState,
                companyID: newValue ? newValue.id : null,
              }));
            }}
            options={companyList}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Select Company Name" />
            )}
          />

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 140,
              marginLeft: "10px",
            }}
            size="small"
            onChange={(event, newValue) => {
              setSelectedIds((prevState) => ({
                ...prevState,
                templateId: newValue ? newValue.id : null,
              }));
            }}
            options={templateList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Select Form Type" />
            )}
          />

<Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            onChange={(event, newValue) => {
              setSelectedIds((prevState) => ({
                ...prevState,
                factoryId: newValue ? newValue.id : null,
              }));
            }}
            options={factoryList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Select Factory" />
            )}
          />

          <div
            style={{ marginLeft: "10px", marginRight: "10px" }}
            id="quotationlist_datefrom"
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                sx={{ width: "200px" }}
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginRight: "10px" }} id="quotationlist_dateto">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{ width: "200px" }}
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </div>

          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
            disabled={!selectedIds?.templateId}
          >
            <SearchIcon />
          </Button>
          <ExcelDownload
            finalData={finalData}
            notNeededColumn={notNeededColumn}
            headerMapping={defaultHeaderMapping}
          />
        </div>
      </div>
      <TableContainer component={Paper} sx={{ mt: 2, p: 2 }}>
        <div style={{ width: "98%", margin: "auto" }} ref={printComponentRef}>
          <div style={{ padding: "0px 5px" }}>
            <table
              // stickyHeader
              aria-label="sticky table"
              size="small"
              style={{ borderCollapse: "collapse", width: "100%" }}
            >
              <thead id="singlebordertable">
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={column.id}
                      style={{
                        width: `${column.minWidth}%`,
                        fontSize: "12px",
                        padding: "5px",
                        textAlign: column.align,
                      }}
                    >
                      <b>{column.label}</b>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody id="singlebordertable">
                {finalData?.length > 0 ? (
                  finalData
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((row, index) => {
                      return (
                        <tr
                          id="pagbreaksborder"
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {columns.map((column, columnIndex) => {
                            let value;

                            if (
                              column.id === "typeIdentificationNumber" ||
                              column.id === "distinctiveNumber" ||
                              column.id === "ultrasonicTest"
                            ) {
                              value = row[column.id] || "";
                              return (
                                <td
                                  style={{
                                    fontSize:
                                      column.id == "ultrasonicTest"
                                        ? "10px"
                                        : "11px",
                                    padding: "5px",
                                    width: "20px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  <TextDisplay text={value} />
                                </td>
                              );
                            } else if (columnIndex === 1) {
                              // Special handling for the column with index 1
                              const value1 = row["equipment_name"] || "";
                              const value2 = row[columns[1].id] || "";
                              const mergedValue = `${value1} ,  ${value2}`;
                              return (
                                <td
                                  style={{
                                    fontSize: "12px",
                                    padding: "5px",
                                    width: "20px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {mergedValue}
                                </td>
                              );
                            } else {
                              if (
                                column.id === "certificateValidity" ||
                                column.id === "inspectionDate" ||
                                column.id === "hydraulicTestDate" ||
                                // column.id === "internalTestDate" ||
                                column.id === "utrasonicNextDueDateCalculate" ||
                                column.id === "hydraulicNextDueDateCalculate" ||
                                column.id === "utrasonicTestDate"
                              ) {
                                let newDate = moment(row[column.id]).format(
                                  "DD-MM-YYYY"
                                );
                                value = row[column.id] ? newDate : null;
                              } else {
                                value = row[column.id];
                              }
                              if (column.id === "id") {
                                value = page * rowsPerPage + index + 1;
                              }
                              return (
                                <td
                                  style={{
                                    fontSize: "12px",
                                    padding: "5px",
                                    width: "20px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={columns.length + 1}>
                      <h6>
                        <b>Data Not Found</b>
                      </h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        /> */}
      </TableContainer>
    </div>
  );
};

export default DishReport;
