import React from "react";
import "./user.css";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoIcon from "@mui/icons-material/Info";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export const userTypes = [
  {
    id: 1,
    label: "Admin",
  },

  {
    id: 3,
    label: "Client",
  },
];
const moduleList = [
  {
    id: 1,
    name: "Dashboard",
  },
  {
    id: 2,
    name: "Competent Master",
  },
  {
    id: 3,
    name: "Products",
  },
  {
    id: 4,
    name: "Shops",
  },
  {
    id: 5,
    name: "Form Reports ",
  },
  {
    id: 6,
    name: "Summary Report",
  },
  {
    id: 7,
    name: "Due Report",
  },
  {
    id: 8,
    name: "Clients",
  },
  {
    id: 9,
    name: "Users",
  },
  {
    id: 10,
    name: "Dish Report",
  }
];

export default function EditUser() {
  const { id } = useParams();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0]);
  const [roles, setRoles] = React.useState("");
  const [executing, setExecuting] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [modules, setModules] = React.useState(moduleList);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [clientID, setclientID] = React.useState(null);
  const [type, setType] = React.useState({});

  console.log("clientID",clientID)

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = async () => {
    setExecuting(true);
    var jsonData = {
      userName: userName,
      password: password,
      clientId:clientID,
      modules:
        roles?.id !== 1
          ? (selectedModules || [])?.map((m) => m.id).join(",")
          : null,
      type: type?.id,
    };
 

    let ret = id
      ? axiosWithToken.patch(BASE_URL + `users/${id}`, jsonData)
      : axiosWithToken.post(BASE_URL + `users`, jsonData);
    ret
      .then((res) => {
        id
          ? toast.success("Updated Sucessfully!")
          : toast.success("User Create Sucessfully!");
        refresh();
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  async function fetchUserDetails() {
    await axiosWithToken.get(BASE_URL + `users/${id}`).then((res) => {

      setUserName(res.data[0].userName);
      setPassword(res.data[0].password);
      setType(userTypes?.find((ele)=>ele.id == res.data[0].type));
      setclientID(res.data[0].clientId)
      if (res.data[0].password) {
        let vals = passVal;

        let lowerCaseLetters = /[a-z]/g;
        res.data[0].password.match(lowerCaseLetters)
          ? (vals[0] = 1)
          : (vals[0] = 0);

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        res.data[0].password.match(upperCaseLetters)
          ? (vals[1] = 1)
          : (vals[1] = 0);

        // Validate numbers
        let numbers = /[0-9]/g;
        res.data[0].password.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

        // Validate length
        res.data[0].password.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
        res.data[0].password.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

        setPasswordVal(vals);
      }
      res.data[0].modules
        ? setSelectedModules(
            res.data[0].modules?.split(",").map((m) => moduleList[m - 1] || [])
          )
        : setSelectedModules([]);
    });
  }

  const fetchClients = () => {
    try {
      axiosWithToken
        .get(BASE_URL + `clients`)
        .then((res) => {
          let data = res.data;
          setClients(data);
        })

    } catch (e) {
      console.log("Failed to fetch  Clients : ", e);
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchUserDetails();
    }
  }, [id]);


  React.useEffect(()=>{
    fetchClients()
  },[])

  return (
    <Paper sx={{ px: 2 }}>
   
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic"
            label="Password *"
            value={password}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal;

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters)
                ? (vals[0] = 1)
                : (vals[0] = 0);

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters)
                ? (vals[1] = 1)
                : (vals[1] = 0);

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

              // Validate length
              e.target.value.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
              e.target.value.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

              setPasswordVal(vals);
              setPassword(e.target.value);
            }}
            style={{ position: "relative" }}
          />
          <InfoIcon
            style={{ color: "gray", position: "absolute", height: "20px" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {isHovered && (
            <div>
              <p id="letter" className={passVal[0] ? "valid" : "invalid"}>
                A <b>lowercase</b> letter
              </p>
              <p id="capital" className={passVal[1] ? "valid" : "invalid"}>
                A <b>capital (uppercase)</b> letter
              </p>
              <p id="number" className={passVal[2] ? "valid" : "invalid"}>
                A <b>number</b>
              </p>
              <p id="lengthMin" className={passVal[3] ? "valid" : "invalid"}>
                Minimum <b>8 characters</b>
              </p>
              <p id="lengthMax" className={passVal[4] ? "valid" : "invalid"}>
                Maximum <b>32 characters</b>
              </p>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={3}>
          <Autocomplete
            key={type}
            size="small"
            id="combo-box-demo"
            options={userTypes}
            getOptionLabel={(option) => option.label || ""}
            value={type}
            renderInput={(params) => (
              <TextField {...params} label="User Types *" />
            )}
            onChange={(e, val) => {
              setType(val);
            }}
          />
        </Grid>


        {type?.id == 3 && (
          <Grid item xs={3} key={type?.id}>
            <Autocomplete
              size="small"
              options={clients}
              value={
                clients?.filter((ele) => ele?.id == clientID)?.[0] || null
              }
              getOptionLabel={(option) => `${option.id}, ${option.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a client"
                  variant="outlined"
                />
              )}
              onChange={(event, value) => {
                setclientID(value?.id);
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            fullWidth
            id="modules"
            size="small"
            options={modules || []}
            defaultValue={undefined}
            value={selectedModules || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />

                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select modules (optional)"
                placeholder="select multiple modules"
              />
            )}
            onChange={(event, value) => setSelectedModules(value || [])}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          style={{ backgroundColor: "grey" }}
          variant="contained"
          size="small"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 2 }}
          disabled={
            executing ||
            userName === "" ||
            password === "" ||
            passVal.reduce((s, v) => s + v, 0) < 5 ||
            (!id && !type?.label)
          }
          onClick={() => {
            onSubmit();
          }}
        >
          {`${id ? "Update" : "Create"} ${
            type && type?.label ? `${type?.label} : ` : ""
          } ${userName}`}
        </Button>
      </Toolbar>
    </Paper>
  );
}
