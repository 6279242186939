import React, { useEffect } from "react";
import { Autocomplete, Button, Grid, Paper, Toolbar } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CommonForm from "../../utils/components/commonForm";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import MultiAddressInput, { onSubmitAddress } from "./multiAddressInput";

const EditClient = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [shippingAddressIds, setShippingAddressIds] = React.useState("");
  const [allAddresses, setAllAddresses] = React.useState([]);
  const [addressIds, setAddressIds] = React.useState("");

  const params = useParams();

  const updateInputObject = (key, value) => {
    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {

    const AllIds = await onSubmitAddress(
      allAddresses,
      setShippingAddressIds,
      setAddressIds
    );

    const payload = {
      ...inputStates,
      address: addressIds && AllIds?.[0]
        ? `${addressIds},${AllIds?.[0]}`
        : addressIds || AllIds?.[0] || "",
        shippingAddress:
        shippingAddressIds && AllIds?.[1]
          ? `${shippingAddressIds},${AllIds?.[1]}`
          : shippingAddressIds || AllIds?.[1] || "",
    };

    let url = BASE_URL;
    if (params.id) {
      axiosWithToken
        .patch(url + `clients/${params.id}`, payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Client edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "clients", payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Client created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchClients = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `clients/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...inputStates,
          ...dataObject,
        };
        dataObject && setInputStates(newData);
        dataObject && setAddressIds(dataObject?.address);
        dataObject && setShippingAddressIds(dataObject?.shippingAddress)
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchClients();
  }, [params.id]);

  const inputFields = [
    // {
    //   type: "Autocomplete",
    //   label: "Instrument Name *",
    //   stateKey: "instrumentId",
    //   valueKey: "id",  // Assuming "id" is the correct property to use here
    //   options: [
    //     { id: 1, name: "voltmeter" },
    //     { id: 2, name: "DC Power" },
    //     { id: 3, name: "Calibration" },
    //   ],
    //   getOptionLabelKey: "name",
    // },

    { type: "TextField", label: "Client Name", stateKey: "name" },
    { type: "TextField", label: "Email ", stateKey: "emailId" },
    { type: "TextField", label: "GST Number", stateKey: "gstNumber" },
    { type: "TextField", label: "Occupier Name", stateKey: "occupierName" },
    { type: "TextField", label: "Vendor Code", stateKey: "vendorCode" },
    { type: "TextField", label: "Contact No", stateKey: "contactDetails" },
    { type: "TextField", label: "Taluka", stateKey: "taluka" },
    { type: "TextField", label: "District", stateKey: "district" },
    { type: "TextField", label: "Pincode", stateKey: "pinCode" },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, p: 4 }}>
        <CommonForm
          inputStates={inputStates}
          updateInputObject={updateInputObject}
          inputFields={inputFields}
        />

<Grid item xs={12}>
          <MultiAddressInput
            addressIds={addressIds}
            shippingAddressIds={shippingAddressIds}
            setAddressIds={setAddressIds}
            setShippingAddressIds={setShippingAddressIds}
            allAddresses={allAddresses}
            setAllAddresses={setAllAddresses}
          />
        </Grid>

        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {params.id ? "Update" : "Save"}
          </Button>
        </Toolbar>
      </Paper>
    </>
  );
}

export default EditClient